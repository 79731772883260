// SPDX-License-Identifier: Apache-2.0
import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "./index.css";
import App from "./App";

ReactDOM.render(
    <App />,
    document.getElementById("root")
);
