import React from "react";
import { StyleSheet, View, Image } from "react-native";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

var container = {
    //padding: 5,
    flex: 1,
    alignItems: "center",
}

var bar = {
    flex: 1,
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: 'center'
}

var landing = {
    // absolute value kills point of using View dimensions
    height: 940,
    width: 800,
    //aspectRatio: 1,
    resizeMode: "contain"
}

var phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
}

// Gap you want to achieve
const gap = 12;

const styles = StyleSheet.create({
    container: {
	flexDirection: "row",
	paddingHorizontal: (gap / -2),
    },
    child: {
	marginHorizontal: gap / 2,
    },
});

function App_Image() {
    return (
	<>
	    <View style={container}>
		<a href="https://shop.original.art">
		    <Image source="assets/original_art_landing_page.jpg" style={landing}/>
		</a>
	    </View>

	    <div style={phantom} />
	    <div className="fixed-bottom">
		<Navbar>
                    <Container style={{height: "15px"}}>
			<Navbar.Brand style={{color: "lightgrey"}}>Original Art, Inc. &copy; 2022</Navbar.Brand>
                    </Container>
		</Navbar>
            </div>
	</>
    );
}

function App() {
    return (
	<>
	    <a href="https://shop.original.art">
		<img src="assets/original_art_landing_page.jpg"
		     alt="Proof and transfer of ownership services for Original Art. For artists, art reps and collectors."/>
	    </a>

	    <div style={phantom} />
	    <div className="fixed-bottom">
		<Navbar>
                    <Container style={{height: "15px"}}>
			<Navbar.Brand style={{color: "lightgrey"}}>Original Art, Inc. &copy; 2022</Navbar.Brand>
			<View style={styles.container}>
			    <View style={styles.child}>
				<a href="mailto:hello@original.art">
				<FontAwesomeIcon icon={faEnvelope} color="lightgrey" size="lg"/></a>
			    </View>
			    <View style={styles.child}>
				<a href="https://twitter.com/OriginalArt">
				<FontAwesomeIcon icon={faTwitter} color="lightgrey" size="lg"/></a>
			    </View>
			    <View style={styles.child}>
				<a href="https://facebook.com/Certified.Original.Art">
				<FontAwesomeIcon icon={faFacebook} color="lightgrey" size="lg"/></a>
			    </View>
			    <View style={styles.child}>
				<a href="https://instagram.com/Certified.Original.Art">
				<FontAwesomeIcon icon={faInstagram} color="lightgrey" size="lg"/></a>
			    </View>
			</View>
                    </Container>
		</Navbar>
            </div>
	</>
    );
}

export default App;

// https://stackoverflow.com/questions/29476165/image-resizing-in-react-native
// https://github.com/facebook/react-native/blob/main/Libraries/Image/Image.android.js
//export default App_Image;
